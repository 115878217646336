import { getDatumByEspecificField, insertDatum, updateDatum } from '@/firebase/helpers'

export default {
  namespaced: true,
  state: {
    loaded: false,
    user: {
      auth: null,
      authToken: null,
      db: undefined,
      session: {
        id: undefined,
        token: '',
        login: false
      },
      storeSelected: null,
      monthlyGoal: 0
    },
    seller: {
      id: null,
      storeSelected: undefined
    },
    storeDeliveryDates: [],
    type: 'user'
  },
  mutations: {
    setLoaded (state) {
      state.loaded = true
    },
    setSellerID (state, payload) {
      state.seller.id = payload
    },
    setSellerStoreSelected (state, payload) {
      state.seller.storeSelected = payload
    },
    setUserAuth (state, payload) {
      state.user.auth = payload
    },
    setUserAuthToken (state, payload) {
      state.user.authToken = payload
    },
    setUserStoreSelected (state, payload) {
      state.user.storeSelected = payload
    },
    setUserMonthlyGoal (state, payload) {
      state.user.monthlyGoal = payload
    },
    setUserSession (state, payload) {
      state.user.session.id = payload
    },
    setUserSessionLogin (state, payload) {
      state.user.session.login = payload
    },
    setUserTokenNotification (state, payload) {
      state.user.session.token = payload
    },
    setUserDB (state, payload) {
      state.user.db = payload
    },
    updateType (state, payload) {
      state.type = payload
    },
    userLogout (state) {
      state.user.auth = null
      state.user.db = undefined
      state.user.session = {
        id: undefined,
        token: '',
        login: false
      }
      state.user.storeSelected = undefined
    }
  },
  actions: {
    async bindUserDB ({ commit, dispatch }, payload) {
      const user = await getDatumByEspecificField(
        'users',
        'firebase_id',
        '==',
        payload.uid
      )
      if (user) {
        commit('setUserDB', user)
        dispatch('sessionLogin')
        if (user.seller_id) {
          commit('setSellerID', user.seller_id)
        }
      }
    },
    async sessionLogin ({ commit, state, rootState }) {
      try {
        if (state.user.session.id === undefined) {
          const session = await getDatumByEspecificField(
            'sessions',
            'accessToken',
            '==',
            state.user.auth.accessToken
          )
          if (session) {
            commit('setUserSession', session.id)
          }

          if (state.user.session.login) {
            const id = 'SO-' + createID(5)
            const deviceInfo = rootState.UI.device
            delete deviceInfo.info.webViewVersion
            await insertDatum('sessions', id, {
              id: id,
              accessToken: state.user.auth.accessToken,
              started_at: new Date(),
              user: state.user.db.id,
              device_info: deviceInfo,
              notifications_token: state.user.session.token
            })
            commit('setUserSession', id)
          }
        }
      } catch (e) {
        console.log(e.message)
      }
    },
    async sessionLogout ({ state, commit }) {
      try {
        if (state.user.session.id !== undefined) {
          await updateDatum('sessions', state.user.session.id, {
            ended_at: new Date()
          })
          commit('userLogout')
        }
        state.type = 'user'
      } catch (e) {
        console.log(e.message)
      }
    }
  }
}

function createID (len) {
  const chars = '0123456789aAbBcCdDeEfFgGhHiIjJkKlLmMnNoOpPqQrRsStTuUvVwWxXyYzZ'
  let randomstring = ''

  for (let i = 0; i < len; i++) {
    const rnum = Math.floor(Math.random() * chars.length)
    randomstring += chars.substring(rnum, rnum + 1)
  }
  return randomstring
}
