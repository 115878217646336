module.exports = {
  namespaced: true,
  state: () => ({
    udt: {},
    udtServiceSelected: {},
    arcus: {}
  }),
  mutations: {
    udtPackageSelected (state, udt) {
      state.udt = udt
    },
    udtServiceSelected (state, udt) {
      state.udtServiceSelected = udt
    },
    arcus (state, udt) {
      state.arcus = udt
    }
  },
  actions: {},
  getters: {}
}
