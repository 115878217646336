<template>
  <div>
    <div
      class="bg-white fixed flex h-screen justify-center left-0 top-0 w-screen z-40"
    >
      <div
        class="flex flex-col h-full items-center justify-center w-full md:max-w-2xl"
      >
       <div
          class="my-8 px-8 space-y-4 text-center md:my-12 md:px-0 md:space-y-8"
        >
          <p class="font-atyp leading-tight text-3xl md:text-5xl">
            amiloz ahora es Beloz
          </p>
        </div>
        <img
          src="/img/user/underMaintenance.png"
          class="w-8/12 md:w-6/12"
          alt="Download"
        />
        <div
          class="my-8 px-8 text-center md:my-12 md:px-0 mx-auto"
        >
          <p class="font-atyp leading-none text-2xl md:text-4xl">
            Puedes visitarnos en nuestra nueva página:
          </p>
        </div>
        <div >
          <a href="https://www.beloz.com.mx/" >
            <img class="-mt-2 h-16 px-6 py-2 rounded-md bg-black hover:bg-gray-800" src="@/assets/img/beloz.svg" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
