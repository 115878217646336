export default {
  namespaced: true,
  state: {
    items: [],
    defaultItems: null
  },
  mutations: {
    setItems (state, payload) {
      state.items = payload
    },
    setDefaultItems (state, payload) {
      state.defaultItems = payload
    }
  }
}
