export default {
  namespaced: true,
  state: {
    cart: {
      show: false
    },
    device: {
      id: null,
      info: null,
      languageCode: null
    },
    appInfo: {
      id: null,
      version: null,
      name: null,
      build: null
    },
    onboarding: {
      show: true
    },
    orderID: null,
    searchQuery: ''
  },
  mutations: {
    hideCart (state) {
      state.cart.show = false
    },
    setDevice (state, payload) {
      state.device = payload
    },
    setAppVersion (state, payload) {
      state.appInfo = payload
    },
    setOrderID (state, payload) {
      state.orderID = payload
    },
    showCart (state) {
      state.cart.show = true
    },
    setSearchQuery (state, payload) {
      state.searchQuery = payload
    }
  }
}
