<template>
  <div class="flex justify-center items-center h-full w-full">
    <img
      class="h-5/6 object-center object-contain w-11/12"
      v-lazy="src"
      :alt="item.id"
      v-if="item.product_presentation.product.images_urls !== null"
    />
  </div>
</template>

<script>
import axios from 'axios'
import { Filesystem, Directory /*, Encoding */ } from '@capacitor/filesystem'

export default {
  computed: {
    deviceInfo () {
      return this.$store.state.UI.device.info
    }
  },
  data () {
    return {
      src: ''
    }
  },
  methods: {
    async getUri (imageName) {
      // const self = this
      Filesystem.getUri({
        directory: Directory.Library,
        path: `product_images/${imageName}`
      }).then((result) => {
        console.log(result.uri)
        // self.src = result.uri
      })
    },
    async storeImage (imageName) {
      const proxyURL =
        'https://us-central1-amiloz-app.cloudfunctions.net/base64Proxy'
      const imageURL =
        'https://firebasestorage.googleapis.com/v0/b/amiloz-app.appspot.com/o/products_images%2F400x400%2F' +
        imageName +
        '_400x400.png?alt=media'

      const response = await axios({
        method: 'post',
        data: {
          url: imageURL
        },
        url: proxyURL
      })

      console.log('loaded image remotely: ' + imageName)

      const base64Data = response.data
      // console.log(imageName)
      // console.log(base64Data)

      const savedFile = await Filesystem.writeFile({
        data: base64Data,
        path: 'product_images/' + imageName,
        directory: Directory.Library
      })

      return savedFile
    }
  },
  async mounted () {
    if (this.deviceInfo.platform !== 'web') {
      if (this.item.product_presentation.product.images_urls) {
        const imageName =
          this.item.product_presentation.product.images_urls[0].split('.')[0]

        const self = this

        Filesystem.readFile({
          directory: Directory.Library,
          path: `product_images/${imageName}`
        })
          .then((readFile) => {
            console.log('LOCAL FILE: ', imageName)
            // console.log(readFile)
            self.getUri(imageName)
            self.src = `data:image/png;base64,${readFile.data}`
          })
          .catch(async (e) => {
            await self.storeImage(imageName)
            Filesystem.readFile({
              directory: Directory.Library,
              path: `product_images/${imageName}`
            })
              .then((readFile) => {
                console.log('LOCAL FILE 2 attempt: ', imageName)
                self.src = `data:image/png;base64,${readFile.data}`
              })
              .catch((e) => {
                console.log('ERROR: ', e)
              })
          })
      }
    } else {
      if (this.item.product_presentation.product.images_urls) {
        this.src = 'https://firebasestorage.googleapis.com/v0/b/amiloz-app.appspot.com/o/products_images%2F400x400%2F' +
          this.item.product_presentation.product.images_urls[0].split('.')[0] +
          '_400x400.png?alt=media'
      }
    }
  },
  props: {
    item: Object
  }
}
</script>
