import { createApp } from 'vue'
import App from './App.vue'
import VueLazyLoad from 'vue3-lazyload'
import axios from 'axios'
import ProductImage from '@/components/ProductImage'
import SegmentedControl from '@/components/SegmentedControl'
import router from './router'
import store from './store'
import { supabase } from '@/supabase'
import Toaster from '@meforma/vue-toaster'
import _ from 'lodash'
import { VueLd } from 'vue-ld'
import vSelect from 'vue-select'
import { Device } from '@capacitor/device'
// font awesome
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faCartShopping, faCircleUser, faXmark } from '@fortawesome/pro-light-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import VueTour from 'vue-tour'
import VueIntro from 'vue-introjs' */
import 'intro.js/introjs.css'
require('vue-tour/dist/vue-tour.css')

const { DateTime } = require('luxon')

// library.add(faCartShopping, faCircleUser, faXmark)
// end font awesome

const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value()
      }
    }
    document.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted: (el) => {
    document.removeEventListener('click', el.clickOutsideEvent)
  }
}

const app = createApp(App)

Device.getInfo()
  .then((deviceInfo) => {
    app.use(VueLd, {
      clientSideId: process.env.VUE_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
      user: {
        key: 'unregistered_visitor',
        custom: {
          ...deviceInfo,
          appVersion: '3.3.0'
        }
      }
    })
  })
  .catch((e) => {
    console.log(e)
  })
  .finally(() => {
    app.config.globalProperties.$API = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      timeout: 10000
      // headers: {'X-Custom-Header': 'foobar'}
    })
    app.config.globalProperties.$amilozBE = axios.create({
      baseURL: process.env.VUE_APP_BACKEND_ENDPOINT
    })
    app.config.globalProperties.$arcusBE = axios.create({
      baseURL: process.env.VUE_APP_ARCUS_ENDPOINT
    })

    app.config.globalProperties.$closeModals = () => {
      router.push({
        query: {}
      })
    }

    app.config.globalProperties.$removeAccents = function (str) {
      try {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      } catch (e) {
        console.log(e.message)
        return ''
      }
    }

    app.config.globalProperties.$createID = function (len) {
      const chars = '123456789ABCDEFGHIJKLMNPQRSTUVWXYZ'
      let randomstring = ''

      for (let i = 0; i < len; i++) {
        const rnum = Math.floor(Math.random() * chars.length)
        randomstring += chars.substring(rnum, rnum + 1)
      }
      return randomstring
    }

    app.config.globalProperties.$supabase = supabase
    app.config.globalProperties.$lodash = _
    app.config.globalProperties.$DateTime = DateTime
    app.config.globalProperties.$currentLocalVersion = '3.3.0'

    app
      // .component('font-awesome-icon', FontAwesomeIcon)
      .component('ProductImage', ProductImage)
      .component('SegmentedControl', SegmentedControl)
      .component('v-select', vSelect)

    app.directive('click-outside', clickOutside)

    app
      /* .use(VueIntro)
      .use(VueTour) */
      .use(Toaster)
      .use(store)
      .use(router)
      .use(VueLazyLoad, {
        loading: '/img/lazy/loading.gif',
        error: '/img/lazy/notFound.png'
      })
      .mount('#app')
  })
