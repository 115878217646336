import { createStore } from 'vuex'

import auth from './modules/auth'
import cart from './modules/cart'
import catalogue from './modules/catalogue'
import categories from './modules/categories'
import tiendas from './modules/tiendas'
import UI from './modules/UI'
import udt from './modules/udt'

import { db } from '@/firebase/app'
import { collection, query, onSnapshot, limit } from 'firebase/firestore'

export default createStore({
  state: {},
  mutations: {
    firestoreUpdate (state, payload) {
      const routeParts = payload.field.split('/')

      let index = null
      function findIndex () {
        for (let i = 0; i < state[routeParts[0]][routeParts[1]].length; i++) {
          const element = state[routeParts[0]][routeParts[1]][i]
          if (element.firestoreId === payload.value.firestoreId) {
            index = i
          }
        }
      }

      switch (payload.changeType) {
        case 'added':
          state[routeParts[0]][routeParts[1]].push(payload.value)
          break

        case 'modified':
          // alert('modified')
          // alert(payload.value)
          findIndex()
          state[routeParts[0]][routeParts[1]].splice(index, 1, payload.value)
          break

        case 'removed':
          // alert('removed')
          // alert(payload.value)
          findIndex()
          state[routeParts[0]][routeParts[1]].splice(index, 1)
          break
      }
    }
  },
  actions: {
    bindCollection (context, payload) {
      const q = query(collection(db, payload.collectionName), limit(2000))
      onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          context.commit('firestoreUpdate', {
            changeType: change.type, // added, modified, removed
            field: payload.storeRoute,
            value: {
              firestoreId: change.doc.id,
              ...change.doc.data()
            }
          })
        })
      })
    }
  },
  modules: {
    auth,
    cart,
    catalogue,
    categories,
    tiendas,
    UI,
    udt
  }
})
