<template>
  <!-- <router-view v-if="finishedMountedFunction" /> -->

  <!-- <LoadingScreen :finishedMountedFunction="finishedMountedFunction" /> -->
  <MaintenanceScreen v-if="maintenanceEnabled" />
  <!-- <UpdateScreen v-if="showUpdateScreen" /> -->
  <!-- <OptionalUpdateScreen v-if="showOptionalUpdateScreen" /> -->

  <!-- <Modals /> -->
  <!-- <Onboarding /> -->
</template>

<script>
// import LoadingScreen from './views/common/LoadingScreen'
import MaintenanceScreen from './views/common/MaintenanceScreen'
// import Modals from './views/Modals/index'
// import Onboarding from './views/common/Onboarding'
// import OptionalUpdateScreen from './views/common/OptionalUpdateScreen'
// import UpdateScreen from './views/common/UpdateScreen'

// import { auth } from '@/firebase/app'
// import { Device } from '@capacitor/device'
import { Dialog } from '@capacitor/dialog'
import { Filesystem, Directory } from '@capacitor/filesystem'
import { FirebaseDynamicLinks } from '@pantrist/capacitor-firebase-dynamic-links'
import { Haptics } from '@capacitor/haptics'
// import { Keyboard } from '@capacitor/keyboard'
// import { onIdTokenChanged } from 'firebase/auth'
import { PushNotifications } from '@capacitor/push-notifications'
// import { StatusBar, Style } from '@capacitor/status-bar'
// import { App } from '@capacitor/app'
// import { getDatumByEspecificField } from './firebase/helpers'

export default {
  components: {
    // LoadingScreen,
    MaintenanceScreen
    // Modals,
    // Onboarding,
    // OptionalUpdateScreen,
    // UpdateScreen
  },
  computed: {
    anyModalOpen () {
      const queryModalOpen = this.$route.query.modal !== undefined
      const cartOpen = this.$store.state.UI.cart.show

      return queryModalOpen || cartOpen
    },
    deviceID () {
      return this.$store.state.UI.device.id
    },
    deviceInfo () {
      return this.$store.state.UI.device.info
    },
    maintenanceEnabled () {
      return true
    },
    showOptionalUpdateScreen () {
      return this.deviceInfo && this.deviceInfo.platform !== 'web' &&
        this.$ld.flags.enableOptionalUpdateAppScreen &&
        this.$ld.flags.appVersion !== this.$currentLocalVersion
    },
    showUpdateScreen () {
      return this.deviceInfo && this.deviceInfo.platform !== 'web' &&
        this.$ld.flags.enableUpdateAppScreen &&
        this.$ld.flags.appVersion !== this.$currentLocalVersion
    },
    user () {
      return this.$store.state.auth.user.db
    }
  },
  data () {
    return {
      finishedMountedFunction: false
    }
  },
  methods: {
    async createCacheDirectory () {
      Filesystem.mkdir({
        directory: Directory.Library,
        path: 'product_images'
      })
        .then((result) => {
          console.log('created cache directory')
        })
        .catch((err) => {
          if (!err.message.includes('already exist')) {
            console.log('error creating cache directory: ' + err)
          }
        })
    },
    listenToDeepLinkOpen () {
      const domain = process.env.VUE_APP_DOMAIN
      FirebaseDynamicLinks.addListener('deepLinkOpen', (data) => {
        const pathArray = data.url.split(domain)
        const appPath = pathArray.pop()
        if (appPath) {
          this.$router.push(appPath)
        }
      })
    },
    async registerPushNotifications () {
      const registerNotifications = async () => {
        let permStatus = await PushNotifications.checkPermissions()

        if (permStatus.receive === 'prompt') {
          permStatus = await PushNotifications.requestPermissions()
        }

        if (permStatus.receive !== 'granted') {
          throw new Error('User denied permissions!')
        } else {
          await PushNotifications.register()
        }
      }

      const addListeners = async () => {
        await PushNotifications.addListener('registration', token => {
          console.info('My token: ' + JSON.stringify(token))
          this.$store.commit('auth/setUserTokenNotification', token.value)
        })

        await PushNotifications.addListener('registrationError', err => {
          console.error('Registration error: ', err.error)
        })

        await PushNotifications.addListener('pushNotificationReceived', notification => {
          console.log('Push notification received: ', notification)
          showAlert(notification.title, notification.body)
        })

        await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
          console.log('Push notification action performed', notification.actionId, notification.inputValue)
        })
      }

      const showAlert = async (title, body) => {
        await Dialog.alert({
          title: title,
          message: body
        })
      }

      // Register for push notifications
      await registerNotifications()
      await addListeners()
    },
    vueLdCallback () {
      console.log('LD user identified')
    }
  },
  // async mounted () {
  //   const self = this

  //   onIdTokenChanged(auth, async (user) => {
  //     if (user) {
  //       self.$store.commit('auth/setUserAuth', user)
  //       try {
  //         const { email } = await getDatumByEspecificField(
  //           'users',
  //           'firebase_id',
  //           '==',
  //           user.uid
  //         )
  //         if (email) {
  //           const body = {
  //             uid: user.uid,
  //             email
  //           }
  //           const { data } = await this.$amilozBE({
  //             method: 'post',
  //             url: '/auth/token',
  //             data: body
  //           })
  //           if (data.ok) {
  //             self.$store.commit('auth/setUserAuthToken', data.token)
  //           }
  //         }
  //       } catch (e) {
  //         console.log(e)
  //       }
  //     } else {
  //       console.log('no active session')
  //     }
  //     self.$store.commit('auth/setLoaded')
  //   })
  //   await this.createCacheDirectory()

  //   // Store data
  //   const items = await this.$amilozBE({
  //     method: 'get',
  //     url: '/catalog/products/all'
  //   })
  //   const defaultItems = await this.$amilozBE({
  //     method: 'post',
  //     url: '/catalog/products/page/1',
  //     data: {
  //       amountOfProducts: 30
  //     }
  //   })
  //   const categories = await this.$amilozBE({
  //     method: 'get',
  //     url: '/catalog/categories'
  //   })

  //   // Device Info
  //   const deviceID = await Device.getId()
  //   const deviceInfo = await Device.getInfo()
  //   const deviceLanguageCode = await Device.getLanguageCode()

  //   // Deeplinks
  //   this.listenToDeepLinkOpen()
  //   // Statusbar
  //   if (deviceInfo.platform !== 'web') {
  //     this.registerPushNotifications()

  //     Keyboard.setAccessoryBarVisible({
  //       isVisible: true
  //     }).catch((e) => {
  //       console.log(e.message)
  //     }).finally(() => {
  //       StatusBar.setStyle({ style: Style.Light })
  //     })
  //     const appVersion = await App.getInfo()
  //     this.$store.commit('UI/setAppVersion', appVersion)
  //   }

  //   if (deviceInfo.platform === 'ios') {
  //     window.addEventListener('statusTap', function () {
  //       self.$store.commit('UI/hideCart')
  //       self.$router.push({ query: {} })
  //     })
  //   }

  //   this.$store.commit('UI/setDevice', {
  //     id: deviceID,
  //     info: deviceInfo,
  //     languageCode: deviceLanguageCode
  //   })
  //   this.$store.commit('catalogue/setItems', items.data.data)
  //   this.$store.commit('catalogue/setDefaultItems', defaultItems.data)
  //   this.$store.commit('categories/updateCategories', categories.data.data)

  //   this.finishedMountedFunction = true
  // },
  watch: {
    anyModalOpen () {
      Haptics.impact({
        style: 'light'
      })
    },
    deviceInfo (newValue) {
      if (newValue && this.user) {
        const options = {
          newUser: {
            key: this.user.email,
            custom: {
              deviceId: this.deviceId,
              name: newValue.name,
              model: newValue.model,
              platform: newValue.platform,
              operatingSystem: newValue.operatingSystem,
              osVersion: newValue.osVersion,
              manufacturer: newValue.manufacturer,
              appVersion: this.$currentLocalVersion
            }
          }
        }
        this.$ld.identify(options, this.vueLdCallback)
      }
    }
  }
}
</script>
<style lang="sass">
@import "@/styles/main.sass"
</style>
