import { initializeApp } from 'firebase/app'
import { browserLocalPersistence, initializeAuth } from 'firebase/auth'
import { enableIndexedDbPersistence, getFirestore } from 'firebase/firestore'

import '@firebase/firestore'
// import 'firebase/functions'
import '@firebase/storage'

import credentials from './credentials'

const app = initializeApp(credentials.config)

// utils
// const db = firebase.firestore()
const auth = initializeAuth(app, {
  persistence: browserLocalPersistence // This uses localStorage
})
const db = getFirestore(app)

enableIndexedDbPersistence(db)
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  })
// const storage = firebase.storage()

/* if (window.location.hostname === 'localhost') {
  firebase.functions().useFunctionsEmulator('http://localhost:5001')
} */

// export utils/refs
export { auth, db }
