import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'User',
    component: () => import(/* webpackChunkName: "user" */ '../views/User/index'),
    children: [
      {
        path: '',
        name: 'TienditaHome',
        component: () => import(/* webpackChunkName: "user-home" */ '../views/User/home')
      },
      {
        path: 'catalogo',
        name: 'TienditaCatalog',
        component: () => import(/* webpackChunkName: "catalogue" */ '../views/User/catalog')
      },
      {
        path: 'promociones',
        name: 'PromotionsModule',
        component: () => import(/* webpackChunkName: "promotions" */ '../views/User/Promotions')
      },
      {
        path: 'amibook',
        name: 'AmibookModule',
        component: () => import(/* webpackChunkName: "amibook" */ '../views/User/Amibook')
      },
      {
        path: '/complete-profile',
        name: 'CompleteProfileModule',
        component: () => import(/* webpackChunkName: "profile" */ '../views/User/Profile')
      },
      {
        path: '/arcus/create',
        name: 'CreateArcusAccount',
        component: () => import(/* webpackChunkName: "arcus" */ '../views/User/Profile/arcus')
      }
    ]
  },
  {
    path: '/ventas',
    name: 'Sales',
    component: () => import(/* webpackChunkName: "sales" */ '../views/Sales/index.vue'),
    children: [
      {
        path: '',
        name: 'SalesHome',
        component: () => import(/* webpackChunkName: "sales-home" */ '../views/Sales/home.vue')
      }
    ]
  },
  {
    path: '/logistica',
    name: 'Logistics',
    component: () => import(/* webpackChunkName: "logistics" */ '../views/Logistics/index.vue'),
    children: [
      {
        path: '',
        name: 'LogisticsHome',
        component: () => import(/* webpackChunkName: "logistics-home" */ '../views/Logistics/home.vue')
      },
      {
        path: 'cargar',
        name: 'LoadLogistics',
        component: () => import(/* webpackChunkName: "logistics-home" */ '../views/Logistics/Carry/index.vue')
      },
      {
        path: 'entregas',
        name: 'DeliveryLogistics',
        component: () => import(/* webpackChunkName: "logistics-home" */ '../views/Logistics/Deliveries/index.vue')
      },
      {
        path: 'devoluciones',
        name: 'ReturnLogistics',
        component: () => import(/* webpackChunkName: "logistics-home" */ '../views/Logistics/Returns/index.vue')
      }
    ]
  },
  {
    path: '/servicios',
    name: 'servicios',
    component: () => import(/* webpackChunkName: "services" */ '@/views/Services/index.vue')
  },
  {
    path: '/servicios/account',
    name: 'NoAccountForm',
    component: () => import(/* webpackChunkName: "services" */ '@/views/Services/NoAccountForm.vue')
  },
  {
    path: '/phone',
    name: 'Recarga',
    component: () => import(/* webpackChunkName: "phone" */ '@/views/Services/phone/layouts/PhoneLayout'),
    children: [
      {
        path: '',
        redirect: { name: 'phone-companies' }
      },
      {
        path: 'companies',
        name: 'phone-companies',
        component: () => import(/* webpackChunkName: "PhoneCompanies" */ '@/views/Services/phone/views/PhoneCompanies.vue')
      },
      {
        path: 'number/:company',
        name: 'phone-package',
        component: () => import(/* webpackChunkName: "PhonePackage" */ '@/views/Services/phone/views/PhoneNumber.vue'),
        props: (route) => {
          const { company } = route.params
          return { company }
        }
      },
      {
        path: 'success',
        name: 'phone-success',
        component: () => import(/* webpackChunkName: "PhoneSuccess" */ '@/views/Services/phone/views/PhoneSuccess.vue')
      },
      {
        path: 'checkout',
        name: 'phone-checkout',
        component: () => import(/* webpackChunkName: "PhoneCheckout" */ '@/views/Services/phone/views/PhoneCheckout.vue')
      },
      {
        path: 'instructions',
        name: 'phone-instructions',
        component: () => import(/* webpackChunkName: "PhoneInstructions" */ '@/views/Services/phone/views/PhoneInstructions.vue')
      },
      {
        path: 'records',
        name: 'phone-records',
        component: () => import(/* webpackChunkName: "PhoneRecords" */ '@/views/Services/phone/views/PhoneRecords.vue')
      }
    ]
  },
  {
    path: '/service',
    name: 'Pago',
    component: () => import(/* webpackChunkName: "service" */ '@/views/Services/service/layouts/ServiceLayout'),
    children: [
      {
        path: '',
        redirect: { name: 'service-list' }
      },
      {
        path: 'services',
        name: 'service-list',
        component: () => import(/* webpackChunkName: "ServiceList" */ '@/views/Services/service/views/ServiceList.vue')
      },
      {
        path: 'form/:operatorId',
        name: 'service-form',
        component: () => import(/* webpackChunkName: "ServiceForm" */ '@/views/Services/service/views/ServiceForm.vue'),
        props: (route) => {
          const { operatorId } = route.params
          return { operatorId }
        }
      },

      {
        path: 'checkout',
        name: 'service-checkout',
        component: () => import(/* webpackChunkName: "ServiceCheckout" */ '@/views/Services/service/views/ServiceCheckout.vue')
      },
      {
        path: 'success',
        name: 'service-success',
        component: () => import(/* webpackChunkName: "serviceSuccess" */ '@/views/Services/service/views/ServiceSuccess.vue')
      }
    ]
  },
  {
    path: '/rewards',
    name: 'services-rewards',
    component: () => import(/* webpackChunkName: "ServicesRewards" */ '@/views/Services/ServiceInfoRewards.vue')
  },
  {
    path: '/wallet',
    name: 'service-wallet',
    component: () => import(/* webpackChunkName: "ServiceWallet" */ '@/views/Services/wallet/WalletInfo.vue')
  },
  {
    path: '/codeScanner',
    name: 'code-scanner',
    component: () => import(/* webpackChunkName: "CodeScanner" */ '@/views/Services/components/BarcodeScanner.vue')
  },
  {
    path: '/:pathMatch(.*)*', // will match everything and put it under `$route.params.pathMatch`
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
