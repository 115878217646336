import axios from 'axios'

const amilozBE = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_ENDPOINT
})

export default {
  namespaced: true,
  state: {
    sellerStores: null,
    userStores: null,
    validatedPhone: null
  },
  mutations: {
    async bindUserStores (state, userID) {
      try {
        const stores = await amilozBE({
          method: 'get',
          url: `/stores/user/${userID}`
        })
        state.userStores = stores.data.data
      } catch (e) {
        console.log(e)
      }
    },
    async bindSellerStores (state, sellerID) {
      try {
        const stores = await amilozBE({
          method: 'get',
          url: `/stores/seller/${sellerID}`
        })
        state.sellerStores = stores.data.data
      } catch (e) {
        console.log(e)
      }
    },
    setValidatedPhone (state, payload) {
      state.validatedPhone = payload
    },
    setNewSellerStore (state, payload) {
      const currentStores = state.sellerStores ? state.sellerStores : []
      currentStores.push(payload)
      state.sellerStores = currentStores
    },
    setNewUserStore (state, payload) {
      const currentStores = state.userStores ? state.userStores : []
      currentStores.push(payload)
      state.userStores = currentStores
    },
    tiendasLogout (state) {
      state = {
        sellerStores: null,
        userStores: null,
        validatedPhone: null
      }
    }
  }
}
