export default {
  namespaced: true,
  state: {
    items: {},
    deliveryDate: null,
    coupon: {
      code: '',
      id: null
    },
    discount: {
      amount: 0,
      type: 'amountInCents'
    },
    referrer: null
  },
  mutations: {
    clear (state) {
      state.items = {}
    },
    clearCouponStatus (state) {
      state.coupon = {
        code: '',
        id: null
      }
      state.discount = {
        amount: 0,
        type: 'amountInCents'
      }
      state.referrer = null
    },
    decreaseItem (state, product) {
      const quantity = state.items[product.id].quantity
      if (quantity <= product.sold_in_multiples_of) {
        delete state.items[product.id]
      } else {
        state.items[product.id].quantity = quantity - product.sold_in_multiples_of
      }
    },
    increaseItem (state, product) {
      if (!state.items[product.id]) {
        state.items[product.id] = {
          id: product.id,
          product: product,
          quantity: product.sold_in_multiples_of
        }
      } else {
        const maxItemsForDelivery = state.items[product.id].product.max_items_for_delivery
          ? state.items[product.id].product.max_items_for_delivery
          : null
        const quantity = state.items[product.id].quantity

        if (!maxItemsForDelivery || !((quantity + product.sold_in_multiples_of) > maxItemsForDelivery)) {
          state.items[product.id].quantity = quantity + product.sold_in_multiples_of
        }
      }
    },
    setDeliveryDate (state, payload) {
      state.deliveryDate = payload
    },
    setCouponStatus (state, payload) {
      state.coupon = payload.coupon
      state.discount = payload.discount
      state.referrer = payload.referrer
    }
  },
  actions: {}
}
