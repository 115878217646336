import { db } from './app'
import {
  collection,
  query,
  getDoc,
  getDocs,
  setDoc,
  doc,
  where,
  orderBy,
  updateDoc
} from 'firebase/firestore'

const statuses = [
  'CREATED',
  'DELIVERED',
  'RESCHEDULED',
  'CANCELED'
]

async function insertDatum (table, id, data) {
  const docRef = await setDoc(doc(db, table, id), data)
  return docRef
}

async function updateDatum (table, id, data) {
  try {
    await updateDoc(doc(db, table, id), data)
    return true
  } catch (e) {
    return false
  }
}

async function getDatumByID (table, id) {
  try {
    const docSnap = await getDoc(doc(db, table, id))
    if (docSnap.exists()) {
      return docSnap.data()
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

async function getDatumByEspecificField (table, field, condition, value) {
  try {
    const q = query(collection(db, table), where(field, condition, value))
    const querySnapshot = await getDocs(q)

    let item = null
    querySnapshot.forEach((doc) => {
      item = doc.data()
    })

    return item
  } catch (e) {
    return false
  }
}

async function getOrders (storeID) {
  try {
    const q = query(collection(db, 'orders'), where('store', '==', storeID), where('status', 'in', statuses), orderBy('created_at', 'desc'))
    const querySnapshot = await getDocs(q)

    const orders = []
    querySnapshot.forEach((doc) => {
      orders.push(doc.data())
    })

    return orders
  } catch (e) {
    return []
  }
}

async function getData (table) {
  try {
    const q = query(collection(db, table))
    const querySnapshot = await getDocs(q)

    const data = []
    querySnapshot.forEach((doc) => {
      data.push(doc.data())
    })

    return data
  } catch (e) {
    return []
  }
}

async function getDataByField (table, field, condition, value) {
  try {
    const q = query(collection(db, table), where(field, condition, value))
    const querySnapshot = await getDocs(q)

    const data = []
    querySnapshot.forEach((doc) => {
      data.push(doc.data())
    })

    return data
  } catch (e) {
    return []
  }
}

async function getOrderedDataByField (table, field, condition, value, orderField, order) {
  try {
    const q = query(
      collection(db, table),
      where(field, condition, value),
      orderBy(orderField, order)
    )
    const querySnapshot = await getDocs(q)

    const data = []
    querySnapshot.forEach((doc) => {
      data.push(doc.data())
    })

    return data
  } catch (e) {
    return []
  }
}

export {
  getData,
  getDataByField,
  getDatumByEspecificField,
  getDatumByID,
  getOrderedDataByField,
  getOrders,
  insertDatum,
  updateDatum
}
