<template>
  <div class="segmented-control bg-gray-200 border border-ink-clear bg-ink grid leading-9 relative rounded-lg text-lg"
    v-bind:class="[
      'grid-cols-' + options.length
    ]">

    <div class="indicator absolute bg-white duration-300 ease-in-out font-semibold h-7 leading-7 left-0.5 rounded-md text-black text-center text-sm transition z-30"
      v-bind:style="{
        transform: 'translate3d(calc(100% * ' + selected.toString() + '), 0, 0)',
        width: 'calc(100% / ' + options.length.toString() + ' - 2px)'
      }">
      {{options[selected]}}
    </div>

    <div class="font-semibold leading-9 relative text-center text-black text-sm z-20"
      v-bind:class="{
        'opacity-40': selected !== i,
        'opacity-100': selected === i
      }"
      v-bind:key="option"
      v-for="(option, i) in options"
      v-on:click="selected = i">
      {{option}}
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      selected: 0
    }
  },
  mounted () {
    if (this.default !== undefined) {
      this.selected = this.default
    }
  },
  props: {
    default: Number,
    options: Array
  },
  watch: {
    selected (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('change-option', this.options[this.selected])
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.segmented-control
  height: 36px

.indicator
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.15)
  transform: translate3d(0, 0, 0)
  top: 3px
</style>
